/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import React, { useEffect, useState } from "react";
import app from "../../firebase";
import { Alert, Box, Button, FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Modal, Paper, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import { makeStyles, withStyles } from '@mui/styles';
import MDButton from "components/MDButton";
import Popup from "reactjs-popup";
import 'reactjs-popup/dist/index.css';
import { useNavigate } from "react-router-dom";
import MDInput from "components/MDInput";
import emailjs from '@emailjs/browser';

const useStyles = makeStyles((theme) => ({
  btnContainer: {
    margin: theme.spacing(2.5, 0),
    display: 'flex',
    justifyContent: 'space-between'
  },
  grid: {
      margin: theme.spacing(1, 0)
  },
  card: {
      borderRadius: 15,
  },
  green: {
      backgroundColor: 'green',
      color: 'white'
  },
  hover: {
      '&:hover': {
          color: '#fff',
      }
  },
  paper: {
    marginBottom: theme.spacing(2),
    backgroundColor: '#530f77',
    borderRadius: 15,
},
table: {
    borderWidth: 0
},
}));
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 15,
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };
function Tables() {
  const { columns, rows } = authorsTableData();
  const { columns: pColumns, rows: pRows } = projectsTableData();
  const[list, setList] = useState([]);
  const [page, setPage] = React.useState(0);
  const classes = useStyles();
  const [usercount, setCUser] = useState(0);
  const [type,setType] = useState(false)
  const [uid,setUid] = useState('')
  const [booklist,setBook] = useState([])
  const [workerlist,setWorker] = useState([])
  const [mybooklist,setMyBook] = useState([])
  const [seletedvid,setSelectedVid] = useState('')
  const [selectedDetail,setSdetail] = useState('')
  const [address,setAddress] = useState('')
  const [phone,setPhone] = useState('')
  const [name,setName] = useState('')
  const [bvn,setBvn] = useState('')
  const [success, setSuccess] = useState('');
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [selecteduser, setSelectedUser] = React.useState("Select Item");
  const [message, setMessage] = useState('');
  const [best, setBest] = useState('');
  const [good, setGood] = useState('');
  const [bad, setBad] = useState('');
  const [role, setRole] = useState('user');
  const [assign, setAssign] = useState('');
  const [selectprog,setSprog] = useState('prog')
  const [selectrating,setRating] = useState('best')
  var [key, setKey] = useState();
  const [open5, setOpen5] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  var [ formData, setFormData ] = useState({
    subject: '', 
    message: ''
  });
  const history = useNavigate()
  

  const handleClose2 = () => {
    setOpen2(false);
  };
  useEffect(() => {
    app.auth().onAuthStateChanged(user => {
      if (user) {
        setUid(user.uid)
        app.database().ref().child('Bookings/').on('value', snapshot => {
            let memberlist = [];
            snapshot.forEach(snap => {
                memberlist.push(snap.val());
            });
            setBook(memberlist)
            })
            app.database().ref().child('lists/'+user.uid).on('value', snapshot => {
                let memberlist = [];
                snapshot.forEach(snap => {
                    memberlist.push(snap.val());
                });
                setMyBook(memberlist)
                })
                app.database().ref().child('workers/').on('value', snapshot => {
                  let memberlist = [];
                  snapshot.forEach(snap => {
                      memberlist.push(snap.val());
                  });
                  setWorker(memberlist)
                  })
                  
        app.database().ref().child('users/'+user.uid+'/type').on('value', snapshot => { 
          if (snapshot.val()=="admin") {
              setType(true)
          } 
          else if (snapshot.val()=="super-admin") {
            setType(true)
            setRole(snapshot.val())
          } 
          else
          {
              setType(false)
          }   
        })
      }
  })
    app.database().ref().child('users/').on('value', snapshot => {
        
      setCUser(snapshot.numChildren()) 
      let memberlist = [];
      snapshot.forEach(snap => {
          memberlist.push(snap.val());
      });
      setList(memberlist)
      })
     
  }, [])
  const handleAdd = () => {
    setLoading(true)
    if (name=='') {
      setError(true)
      setMessage("name required")
      setLoading(false)
    }
    else if (address=='') {
      setError(true)
      setMessage("address required")
      setLoading(false)
    }
    else if (phone=='') {
      setError(true)
      setMessage("phone number required")
      setLoading(false)
    }
    else if (bvn=='') {
      setError(true)
      setMessage("bvn required")
      setLoading(false)
    }
    else{
      var key = app.database().ref().push().key;
  app.database().ref().child('workers/'+key).set({
    name:name,
    loc:address,
    phone: phone,
    bvn: bvn,
    key: key
  }) 
  setLoading(false)  
  setSuccess("Successful!")
    }
 
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {
        type ?
        <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Users
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
              <TableContainer variant="elevation" style={{borderTopLeftRadius: 0, borderTopRightRadius: 0}} elevation={0} component={Paper}>
                    <Table aria-label="customized table" >
                        
                            <TableRow>
                                <StyledTableCell>
                                   <MDTypography variant="h6" >Name</MDTypography> 
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                <MDTypography variant="h6"> Email</MDTypography>  
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                <MDTypography variant="h6">Role</MDTypography>                                   
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                <MDTypography variant="h6">Actions</MDTypography>
                                </StyledTableCell>
                            </TableRow>
                        
                        <TableBody>
                            { list.length === 0 ? (
                                <TableRow>
                                    <StyledTableCell scope="row">
                                        No Data Available.
                                    </StyledTableCell>
                                </TableRow>
                            ) : list.map((row) => (
                                <StyledTableRow key={row.email}>
                                    <StyledTableCell
                                        scope="row"
                                        //component={NavLink}
                                        //to={`profile/${row.id}`}
                                        //onClick={() => handleOnClick(row.id)}
                                        >
                                        {row.firstname}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {row.email}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {row.type}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                    {
                                      role === "super-admin"?
                                       <MDButton
                                                        variant="gradient" color="info" fullWidth
                                                        onClick={()=>{
                                                            if(row.type=="admin")
                                                            {
                                                                app.database().ref().child('users/'+row.uid).update({
                                                                    type: 'super-admin',
                                                                    })
                                                            }
                                                            else if(row.type=="super-admin"){
                                                                app.database().ref().child('users/'+row.uid).update({
                                                                    type: 'user',
                                                                    })  
                                                            }
                                                           else
                                                            {
                                                                app.database().ref().child('users/'+row.uid).update({
                                                                    type: 'admin',
                                                                    })
                                                            }
                                                        }}
                                                    >
                                                        Change role
                                                    </MDButton>
                                                    :
                                                    <b></b>
                                    }
                                                   
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[20]}
                                    rowsPerPage={list.length}
                                    count={list.length}
                                    page={page}
                                    onChangePage={handleChangePage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
              </MDBox>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Workers
                </MDTypography>
              </MDBox>
              <MDBox color="text" px={2}>
        <MDButton variant="gradient" color="success"  onClick={()=>{setOpen(true)}}>
               Add employee
              </MDButton>
        </MDBox>
              <MDBox pt={3}>
              <TableContainer variant="elevation" style={{borderTopLeftRadius: 0, borderTopRightRadius: 0}} elevation={0} component={Paper}>
                    <Table aria-label="customized table" >
                        
                            <TableRow>
                                <StyledTableCell>
                                   <MDTypography variant="h6" >Name</MDTypography> 
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                <MDTypography variant="h6"> Phone number</MDTypography>  
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                <MDTypography variant="h6">BVN</MDTypography>  
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                <MDTypography variant="h6"> Address</MDTypography>  
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                <MDTypography variant="h6">Rating</MDTypography>                                   
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                <MDTypography variant="h6">Actions</MDTypography>
                                </StyledTableCell>
                            </TableRow>
                        
                        <TableBody>
                            { workerlist.length === 0 ? (
                                <TableRow>
                                    <StyledTableCell scope="row">
                                        No Data Available.
                                    </StyledTableCell>
                                </TableRow>
                            ) : workerlist.map((row) => (
                                <StyledTableRow key={row.email}>
                                    <StyledTableCell
                                        scope="row"
                                        //component={NavLink}
                                        //to={`profile/${row.id}`}
                                        //onClick={() => handleOnClick(row.id)}
                                        >
                                        {row.name}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {row.phone}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {row.bvn}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {row.loc}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                    <b style={{color:"blue",cursor:"pointer"}} 
                                       onClick={()=>{ 
                                        app.database().ref().child('rating/'+row.name+"/best").on('value', snapshot => { 
                                        setBest(snapshot.numChildren())
                                      })
                                        app.database().ref().child('rating/'+row.name+"/good").on('value', snapshot => { 
                                        setGood(snapshot.numChildren())
                                      })
                                        app.database().ref().child('rating/'+row.name+"/bad").on('value', snapshot => { 
                                        setBad(snapshot.numChildren())
                                      })
                                        setOpen4(true)
                                       
                                       }}>View</b>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                    <Popup trigger={<MDButton
                                                        variant="gradient" color='error' style={{marginBottom:"1rem",Zindex:"1"}} fullWidth>
                                                        Remove
                                                    </MDButton>} position="left center">
                                                    <p>Are you sure?</p>
                                                    <MDButton  variant="gradient" color='error'
                                                     onClick={()=>{
                                                        app.database().ref().child('workers/'+row.key).remove() 
                                                        
                                                    }}>Yes</MDButton>
                                                    </Popup>         
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[20]}
                                    rowsPerPage={workerlist.length}
                                    count={workerlist.length}
                                    page={page}
                                    onChangePage={handleChangePage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
              </MDBox>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Bookings
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
              <TableContainer variant="elevation" style={{borderTopLeftRadius: 0, borderTopRightRadius: 0}} elevation={0} component={Paper}>
                    <Table aria-label="customized table" >
                      
                            <TableRow>
                                <StyledTableCell>
                                   <MDTypography variant="h6" >Name</MDTypography> 
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                <MDTypography variant="h6"> Email</MDTypography>  
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                <MDTypography variant="h6">Order token</MDTypography>                                   
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                <MDTypography variant="h6">Order details</MDTypography>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                <MDTypography variant="h6">Assigned</MDTypography>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                <MDTypography variant="h6">Action</MDTypography>
                                </StyledTableCell>
                            </TableRow>
                        <TableBody>
                            { booklist.length === 0 ? (
                                <TableRow>
                                    <StyledTableCell scope="row">
                                        No Data Available.
                                    </StyledTableCell>
                                </TableRow>
                            ) : booklist.map((row) => (
                                <StyledTableRow key={row.email}>
                                    <StyledTableCell
                                        scope="row"
                                        //component={NavLink}
                                        //to={`profile/${row.id}`}
                                        //onClick={() => handleOnClick(row.id)}
                                        >
                                        {row.name}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {row.email}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        #{row.title}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                    <b style={{color:"blue",cursor:"pointer"}} 
                                       onClick={()=>{
                                        console.log("heare")
                                        setOpen2(true)
                                        setSelectedVid(row.vid)
                                        setSdetail(row.detail)
                                       }}>View</b>
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                    <b style={{color:"blue",cursor:"pointer"}} 
                                       onClick={()=>{
                                        setKey(row.key)
                                        app.database().ref().child('assign/'+row.key).on('value', snapshot => {
                                          let memberlist = [];
                                          snapshot.forEach(snap => {
                                              memberlist.push(snap.val());
                                          });
                                          setAssign(memberlist)
                                          })
                                        setOpen3(true)
                                       }}>View</b>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                                    
                                                    <Popup trigger={<MDButton
                                                        variant="gradient" color='error' style={{marginBottom:"1rem",Zindex:"1"}}>
                                                        Remove
                                                    </MDButton>} position="left center">
                                                    <p>Are you sure?</p>
                                                    <MDButton  variant="gradient" color='error'
                                                     onClick={()=>{
                                                        app.database().ref().child('Bookings/'+row.key).remove() 
                                                        app.database().ref().child('lists/'+row.uid+"/"+row.key).remove() 
                                                    }}>Yes</MDButton>
                                                    </Popup>
                                                    <MDButton
                                                    style={{marginBottom:"1rem",Zindex:"1"}}
                                                        variant="gradient" color="success" 
                                                        onClick={()=>{
                                                          setKey(row.key)
                                                          app.database().ref().child('assign/'+row.key).on('value', snapshot => {
                                                          let memberlist = [];
                                                          snapshot.forEach(snap => {
                                                              memberlist.push(snap.val());
                                                          });
                                                          setAssign(memberlist)
                                                          })
                                                          setOpen3(true)
                                                        }}
                                                    >
                                                        Assign
                                                    </MDButton>
                                                    <MDButton
                                                    style={{marginBottom:"1rem",Zindex:"1"}}
                                                        variant="gradient" color="info" 
                                                        onClick={()=>{
                                                          history("/chat-admin/"+row.uid)
                                                        }}
                                                    >
                                                        Chat
                                                    </MDButton>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[20]}
                                    rowsPerPage={booklist.length}
                                    count={booklist.length}
                                    page={page}
                                    onChangePage={handleChangePage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      :
     <>
      
     </>
      
      }
      <Modal
        hideBackdrop
        open={open2}
        onClose={handleClose2}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 500 }}>
        
        <video width="320" height="240" controls>
  <source src={seletedvid} type="video/mp4"/>
Your browser does not support the video tag.
</video>
<p>{selectedDetail}</p>
          <Button onClick={handleClose2}>Close</Button>
        </Box>
      </Modal>
      <Modal
        hideBackdrop
        open={open}
        onClose={()=>{
          setOpen(false)
        }}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 500 }}>
        {error ? (
                    <Alert onClose={() => {setError(false)}} severity="error">
                        {message}.
                    </Alert>
                ) : null}
                {success ? (
                    <Alert onClose={() => {setSuccess(false)}} severity="success">
                        {success}
                    </Alert>
                ) : null}
        <MDInput type="name" label="Name" fullWidth style={{margin:"1rem 0px"}}
           value={name}
            onChange={e => setName(e.target.value)}
         />
          <MDInput type="phone" label="Phone number" fullWidth style={{margin:"1rem 0px"}}
           value={phone}
            onChange={e => setPhone(e.target.value)}
         />
        <MDInput type="text" label="BVN" fullWidth style={{margin:"1rem 0px"}}
           value={bvn}
            onChange={e => setBvn(e.target.value)}
         />
         <MDInput type="address" label="Address" fullWidth style={{margin:"1rem 0px"}}
           value={address}
            onChange={e => setAddress(e.target.value)}
         />
          <Button onClick={()=>{
            setOpen(false)
          }} disabled={loading}>Close</Button>
          <Button onClick={handleAdd} disabled={loading}>Add employee</Button>
        </Box>
      </Modal>
      <Modal
        hideBackdrop
        open={open3}
        onClose={()=>{
          setOpen3(false)
         
        }}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 500 }}>
        <h3>Assigned Workers</h3>
        <div style={{minHeight:"4rem"}}>
        <TableContainer variant="elevation" style={{borderTopLeftRadius: 0, borderTopRightRadius: 0}} elevation={0} component={Paper}>
                    <Table aria-label="customized table" >
                        
                            <TableRow>
                                <StyledTableCell>
                                   <MDTypography variant="h6" >Name</MDTypography> 
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                <MDTypography variant="h6">Actions</MDTypography>
                                </StyledTableCell>
                            </TableRow>
                        
                        <TableBody>
                            { assign.length === 0 ? (
                                <TableRow>
                                    <StyledTableCell scope="row">
                                        No Data Available.
                                    </StyledTableCell>
                                </TableRow>
                            ) : assign.map((row) => (
                                <StyledTableRow key={row.key}>
                                    <StyledTableCell
                                        scope="row"
                                        //component={NavLink}
                                        //to={`profile/${row.id}`}
                                        //onClick={() => handleOnClick(row.id)}
                                        >
                                        {row.name}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                    <Button
                                     style={{marginBottom:"1rem",color:"red"}}  
                                    onClick={()=>{
                                      app.database().ref().child('assign/'+key+"/"+row.key).remove() 
                                      
                                  }}>
                                    UnAssign
                                </Button>      
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[20]}
                                    rowsPerPage={assign.length}
                                    count={assign.length}
                                    page={page}
                                    onChangePage={handleChangePage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
        </div>
        <FormControl fullWidth>
                            <label id="demo-simple-select-label">Assign worker</label>
                            <Select
                            style={{color:"black"}}
                            placeholder="Select User"
                            value={selecteduser}
                            onChange={(e)=>{
                             
                                setSelectedUser(e.target.value)
                            }}
                            > 
                            {workerlist.map((row) =>  ( 
                            <MenuItem value={row.name}>
                            {row.name}
                            </MenuItem>
                            ))}
                            </Select>
                            </FormControl>   
                            <Button onClick={()=>{
                              if(selecteduser!='')
                              {
                                var token = app.database().ref().push().key;
                               app.database().ref().child('assign/'+key+"/"+token).set({
                              name:selecteduser,
                              key: token
                              }) 
                             setSelectedUser('')  
                              }
                              
          }}>Assign</Button>
          <Button onClick={()=>{
            setOpen3(false)
           
          }} disabled={loading}>Close</Button>
        </Box>
      </Modal>
      <Modal
        hideBackdrop
        open={open4}
        onClose={()=>{
          setOpen4(false)
        }}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 500 }}>
        <p>The best service: {best}</p> 
        <p> Good, but could be better: {good} </p>
        <p> Bad service: {bad} </p>
       
          <Button onClick={()=>{
            setOpen4(false)
          }} >Close</Button>
          
        </Box>
      </Modal>
      <Modal
        hideBackdrop
        open={open5}
        onClose={()=>{setOpen3(false)}}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 500 }}>
      
        <FormControl>
  <FormLabel id="demo-radio-buttons-group-label">Progress</FormLabel>
  <RadioGroup
    aria-labelledby="demo-radio-buttons-group-label"
    onChange={(e)=>{
      setSprog(e.target.value)
      
    }}
    defaultValue={selectprog}
    name="radio-buttons-group"
  >
    <FormControlLabel value="comp" control={<Radio />} label="Complete" />
    <FormControlLabel value="prog" control={<Radio />} label="In-progress" />
  </RadioGroup>
</FormControl>
  {
          selectprog ==="comp" ?
          <FormControl>
  <FormLabel id="demo-radio-buttons-group-label">Rate Our Workers</FormLabel>
  <RadioGroup
    aria-labelledby="demo-radio-buttons-group-label"
    onChange={(e)=>{
      setRating(e.target.value)
      
    }}
    defaultValue={selectrating}
    name="radio-buttons-group"
  >
    <FormControlLabel value="best" control={<Radio />} label="The best service" />
    <FormControlLabel value="good" control={<Radio />} label="Good, but could be better" />
    <FormControlLabel value="bad" control={<Radio />} label="Bad service" />
  </RadioGroup>
</FormControl>
:
null
        }
<br/>
          <Button onClick={()=>{setOpen5(false)}}>Close</Button>
          <Button onClick={()=>{
             app.database().ref().child('Bookings/'+key).update({
                              status:selectprog
                              }) 
                              app.database().ref().child('lists/'+uid+"/"+key).update({
                              status:selectprog
                              }) 
                              if(selectprog==="comp"){
                                app.database().ref().child('assign/'+key).on('value', snapshot => { 
                                  let memberlist = [];
                                    snapshot.forEach(snap => {
                                        memberlist.push(snap.val());
                                    });
                                    memberlist.map((row) => (
                               app.database().ref().child('rating/'+row.name+"/"+selectrating+"/"+key).set({
                                    rate: selectrating
                                  })
                                ))
                                emailjs.send(
                                    process.env.REACT_APP_EMAILJS_SERVICE_ID,
                                    process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
                                    formData, process.env.REACT_APP_EMAILJS_USER_ID
                                  )
                                  .then((result) => {
                                    setFormData({
                                      subject: 'Completed Job', 
                                      message: 'A job has been completed'
                                    })
                                  
                                  }, (error) => {
                                  
                                  });
                                })
                                
                              }
                              setOpen5(false)
          }}>Set progress</Button>
        </Box>
      </Modal>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
