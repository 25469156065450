import firebase from 'firebase/compat/app'
import "firebase/compat/auth"
import "firebase/compat/database"
import 'firebase/compat/storage';


var app = firebase.initializeApp({
    apiKey: "AIzaSyDrhdpfWOJMRrky8NSsCDspwHp0nL2aPJU",
    authDomain: "spakle-3c1fd.firebaseapp.com",
    projectId: "spakle-3c1fd",
    storageBucket: "spakle-3c1fd.appspot.com",
    messagingSenderId: "614206858328",
    appId: "1:614206858328:web:b518000eeddd2bfb6cd62d",
    databaseURL: "https://spakle-3c1fd-default-rtdb.firebaseio.com/" 
})
export const storage = app.storage();

export var auth = app.auth();
export default app