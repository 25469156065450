import React, { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";


// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";

// Data
import data from "layouts/dashboard/components/Projects/data";
import MDButton from "components/MDButton";
import {  Box, Button, FormControl, FormControlLabel, FormLabel, 
  Modal, Paper, Radio, RadioGroup, Table, TableBody, TableCell, 
  TableContainer, TableFooter, TablePagination, TableRow, 
  TextField, TextareaAutosize, MenuItem, InputLabel, Select, Alert, Checkbox} from "@mui/material";
import MDInput from "components/MDInput";
import app, { storage } from "../../../../firebase";
import { makeStyles, withStyles } from "@mui/styles";
import Popup from "reactjs-popup";
import 'reactjs-popup/dist/index.css';
import { useNavigate } from "react-router-dom";
import emailjs from '@emailjs/browser';
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';


function Projects() {
  const { columns, rows } = data();
  const [video, setVideo] = useState();
  const [age, setAge] = useState('');
  const [detail, setDetail] = useState('');
  const [address, setAddress] = useState('');
  const [success, setSuccess] = useState('');
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = useState('');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [service, setService] = useState('');
  const [a, setA] = React.useState('');
  const [b, setB] = React.useState('');
  const [c, setC] = React.useState('');
  const [d, setD] = React.useState('');
  const [ee, setE] = React.useState('');
  const [f, setF] = React.useState('');
  const [g, setG] = React.useState('');
  const [h, setH] = React.useState('');
  const [i, setI] = React.useState('');
  const [j, setJ] = React.useState('');
  const [k, setK] = React.useState('');
  const [l, setL] = React.useState('');
  const [m, setM] = React.useState('');
  const [n, setN] = React.useState('');
  const [o, setO] = React.useState('');
  const [p, setP] = React.useState('');
  
  const onVideochange= (event) =>
{
    if (event.target.files && event.target.files[0]) {
        console.log("here")
        setVideo(event.target.files[0])
      }
}

function handleBook (e){
  setLoading(true)
  if (!name) {
    setError(true)
    setLoading(false)
  return setMessage("Your name is required")
} 
if (!phone) {
  setError(true)
  setLoading(false)
return setMessage("Your phone number is required")
} 
if (!address) {
  setError(true)
  setLoading(false)
return setMessage("Your Address is required")
} 
if (!detail) {
  setError(true)
  setLoading(false)
return setMessage("Tell us about what you need us to clean")
} 

if (!email) {
    setError(true)
    setLoading(false)
    return setMessage('Your Email is required');
} else if (!/\S+@\S+\.\S+/.test(email)) {
    setError(true)
    setLoading(false)
    return setMessage('Email address is invalid');
}
  if (video!=null) {
    var key = app.database().ref().push().key;
  const uploadTask = storage.ref(`videos/${key}.mp4`).put(video);
  uploadTask.on('state_changed', 
  (snapshot) => {
    console.log('uploading');
  }, 
  (error) => {
       // error function ....
    console.log(error);
    setError(true)
    setMessage("failed")
    setLoading(false)
  }, 
() => {
    // complete function ....
    storage.ref('videos').child(key+".mp4").getDownloadURL().then(url => {
       emailjs.send("service_4ic4joh","template_j97uyum",{
        subject: 'Order',
        email: email,
        comp_name:"Sparkles",
        message: 'Thank you '+name+' for making your order on our website. We will contact you soon.'
      },"YISELsQpI9_jfGmwY")
        .then((result) => {
         
          emailjs.send("service_4ic4joh","template_7olwbmh",{
            subject: 'New Order',
            email: 'info@sparkles-ng.com',//info@sparkles-ng.com
            phone: phone,
            addy: address,
            type: a,
            b: b,
            c: c,
            d: d,
            e: ee,
            f: f,
            g: g,
            h: h,
            i: i,
            j: j,
            k: k,
            l: l,
            m: m,
            n: n,
            o: o,
            p: p,
            desc: detail,
            from: email,
            message: 'New Order from '+name,
            url: url
          },"YISELsQpI9_jfGmwY")
          .then(function(response) {
             console.log('SUCCESS!', response.status, response.text);
             
          }, function(error) {
             console.log('FAILED...', error);
             setError(true)
             setMessage("failed")
             setLoading(false)
          });
        }, (error) => {
          setError(true)
          console.log('FAILED...', error);
          setMessage("failed")
          setLoading(false)
        });
    })
   
    setLoading(false)  
    setSuccess("Successful!")
});  
  }
  else{
    emailjs.send("service_4ic4joh","template_j97uyum",{
      subject: 'Order',
      email: email,
      comp_name:"Sparkles",
      message: 'Thank you '+name+' for making your order on our website. We will contact you soon.'
    },"YISELsQpI9_jfGmwY")
      .then((result) => {
       
        emailjs.send("service_4ic4joh","template_7olwbmh",{
          subject: 'New Order',
          email: 'info@sparkles-ng.com',//info@sparkles-ng.com
          phone: phone,
          addy: address,
          type: a,
          b: b,
          c: c,
          d: d,
          e: ee,
          f: f,
          g: g,
          h: h,
          i: i,
          j: j,
          k: k,
          l: l,
          m: m,
          n: n,
          o: o,
          p: p,
          desc: detail,
          from: email,
          message: 'New Order from '+name,
        },"YISELsQpI9_jfGmwY")
        .then(function(response) {
           console.log('SUCCESS!', response.status, response.text);
           
        }, function(error) {
           console.log('FAILED...', error);
           setError(true)
           setMessage("failed")
           setLoading(false)
        });
      }, (error) => {
        setError(true)
        console.log('FAILED...', error);
        setMessage("failed")
        setLoading(false)
      });
      setLoading(false)  
      setSuccess("Successful!")
  } 
   
}
  return (
    <Card>
      <>
      {error ? (
                    <Alert onClose={() => {setError(false)}} severity="error">
                        {message}.
                    </Alert>
                ) : null}
                {success ? (
                    <Alert onClose={() => {setSuccess(false)}} severity="success">
                        {success}
                    </Alert>
                ) : null}
     <Box>
     <TableContainer variant="elevation" component={Paper}>
              
               <div>
               <h4 style={{textAlign:"center"}}>Book Online</h4>
               <Box
     component="form"
     sx={{
       '& > :not(style)': { m: 1, width: '90vw' },
     }}
     noValidate
     autoComplete="off"
   >
   <TextField id="outlined-basic" label="Name" variant="outlined" fullWidth
      value={name}
      onChange={e => setName(e.target.value)}
     />
     <TextField id="outlined-basic" label="Email" variant="outlined" fullWidth
       value={email}
      onChange={e => setEmail(e.target.value)}
     />
     <TextField id="outlined-basic" label="Phone" variant="outlined" fullWidth
     type='tel'
      value={phone}
      onChange={e => setPhone(e.target.value)}   
     />
     <TextField id="outlined-basic" label="Address" variant="outlined" fullWidth
      value={address}
      onChange={e => setAddress(e.target.value)}
     />
    <div style={{display:"flex", flexWrap:"wrap",flex:"50%"}}>
    
     <FormControlLabel
            control={
              <Checkbox  onChange={(e)=>{if(a==''){setA('Janitorial Service')}else{setA('')}}} name='Janitorial Service'/>
            }
            label="Janitorial Service"
          />
      <FormControlLabel
            control={
              <Checkbox onChange={(e)=>{if(b==''){setB('Home Cleaning Service')}else{setB('')}}} name='Home Cleaning Service'/>
            }
            label="Home Cleaning Service"
          />
      <FormControlLabel
            control={
              <Checkbox onChange={(e)=>{if(c==''){setC('Deep/Detailed Cleaning')}else{setC('')}}} name='Deep/Detailed Cleaning'/>
            }
            label="Deep/Detailed Cleaning"
          />
      <FormControlLabel
            control={
              <Checkbox  onChange={(e)=>{if(d==''){setD('Spring Cleaning')}else{setD('')}}} name='Spring Cleaning'/>
            }
            label="Spring Cleaning"
          />
       <FormControlLabel
            control={
              <Checkbox  onChange={(e)=>{if(ee==''){setE('Post Construction Cleaning')}else{setE('')}}} name='Post Construction Cleaning'/>
            }
            label="Post Construction Cleaning"
          />
       <FormControlLabel
            control={
              <Checkbox  onChange={(e)=>{if(f==''){setF('Upholstery Cleaning')}else{setF('')}}} name='Upholstery Cleaning'/>
            }
            label="Upholstery Cleaning"
          />
       <FormControlLabel
            control={
              <Checkbox  onChange={(e)=>{if(g==''){setG('Carpet Cleaning')}else{setG('')}}} name='Carpet Cleaning'/>
            }
            label="Carpet Cleaning"
          />
        <FormControlLabel
            control={
              <Checkbox  onChange={(e)=>{if(h==''){setH('Pressure/Jet Wash')}else{setH('')}}} name='Pressure/Jet Wash'/>
            }
            label="Pressure/Jet Wash"
          />
        <FormControlLabel
            control={
              <Checkbox  onChange={(e)=>{if(i==''){setI('Floor Burnishing and Polishing')}else{setI('')}}} name='Floor Burnishing and Polishing'/>
            }
            label="Floor Burnishing and Polishing"
          />
        <FormControlLabel
            control={
              <Checkbox  onChange={(e)=>{if(j==''){setJ('Fumigation')}else{setJ('')}}} name='Fumigation'/>
            }
            label="Fumigation"
          />
         <FormControlLabel
            control={
              <Checkbox  onChange={(e)=>{if(k==''){setK('Swimming Pool Cleaning')}else{setK('')}}} name='Swimming Pool Cleaning'/>
            }
            label="Swimming Pool Cleaning"
          />
         <FormControlLabel
            control={
              <Checkbox  onChange={(e)=>{if(l==''){setL('End of tenancy cleaning')}else{setL('')}}} name='End of tenancy cleaning'/>
            }
            label="End of tenancy cleaning"
          />
         <FormControlLabel
            control={
              <Checkbox  onChange={(e)=>{if(m==''){setM("Window cleaning of high rising building")}else{setM('')}}} name='Window cleaning of high rising building'/>
            }
            label="Window cleaning of high rising building"
          />
             <FormControlLabel
            control={
              <Checkbox  onChange={(e)=>{if(n==''){setN('Window cleaning')}else{setN('')}}} name='Window cleaning'/>
            }
            label="Window cleaning"
          />
           <FormControlLabel
            control={
              <Checkbox  onChange={(e)=>{if(o==''){setO('After party cleaning')}else{setO('')}}} name='After party cleaning'/>
            }
            label="After party cleaning"
          />
           <FormControlLabel
            control={
              <Checkbox  onChange={(e)=>{if(p==''){setP('Office/Commercial cleaning')}else{setP('')}}} name='Office/Commercial cleaning'/>
            }
            label="Office/Commercial cleaning"
          />
    </div>
     <TextareaAutosize
 aria-label="empty textarea"
 placeholder="Description"
 style={{ width: "90%", height: 100 }}
 value={detail}
 onChange={e => setDetail(e.target.value)}
/>
<div style={{margin:"10px auto"}}>
{
 video ?
 <>
 <a style={{cursor:"pointer",fontSize:"15px",marginRight:"1rem"}}>video.mp4</a> 
 <input
 accept="video/mp4"
 style={{ display: 'none' }}
 id="raised-button-file"
 type="file"
 onChange={onVideochange}
/>
<label htmlFor="raised-button-file">
<a style={{color:"blue",cursor:"pointer",fontSize:"15px",marginRight:"1rem"}}>{"+Change Video"}</a>
</label> 
 
 </>    
 :
 <>
 <input
 accept="video/mp4"
 style={{ display: 'none' }}
 id="raised-button-file"
 type="file"
 onChange={onVideochange}
/>
<label htmlFor="raised-button-file">
<a style={{color:"blue",cursor:"pointer",fontSize:"15px",marginRight:"1rem"}}>{"+Upload Video"}</a>
</label> 
 </>
}

<Button variant="contained"  onClick={handleBook} disabled={loading} style={{color:"white"}}>Book</Button>

</div>
   </Box>
   <hr style={{width:"60%", margin:"5px auto",border:"2px bold black",borderRadius:"2px"}}/>
   <h4 style={{textAlign:"center"}}>You prefer phone call?</h4>
   <p style={{textAlign:"center",fontSize:"15px"}}>You can call us on these numbers: </p>
   <p style={{textAlign:"center",fontSize:"16px"}}>+234 806 543 6384, +234 909 835 1689</p>
   <h4 style={{textAlign:"center",color:"green"}}>If you prefer WhatsApp, click on the whatsapp icon</h4>
   <WhatsAppWidget CompanyIcon='' phoneNumber="2349098351689" />
   </div>
             
     </TableContainer>
     </Box>
     
     </>
    </Card>
  );
}

export default Projects;
