/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

// Material Dashboard 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import burceMars from "assets/images/bruce-mars.jpg";
import backgroundImage from "assets/images/bg-profile.jpeg";
import { makeStyles } from "@mui/styles";
import { Alert, Button, TextField } from "@mui/material";
import app from "../../../../firebase";
import {
  EmailAuthProvider,
} from 'firebase/auth'
import MDButton from "components/MDButton";

const useStyles = makeStyles((theme) => ({
  btnContainer: {
    margin: theme.spacing(2.5, 0),
    display: 'flex',
    justifyContent: 'space-between'
  },
  grid: {
      margin: theme.spacing(1, 0)
  },
  card: {
      borderRadius: 15,
  },
  green: {
      backgroundColor: 'green',
      color: 'white'
  },
  hover: {
      '&:hover': {
          color: '#fff',
      }
  },
  paper: {
    marginBottom: theme.spacing(2),
    backgroundColor: '#530f77',
    borderRadius: 15,
},
table: {
    borderWidth: 0
},
}));

function Header({ children }) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
const [name,setName] = useState("")
  const [last,setLast] = useState("")
  const [select,setSelect] = useState(1)
  const classes = useStyles();
  const [show, setShow] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState('');
  const [error, setError] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [Cpassword, setCPassword] = React.useState('');

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    app.auth().onAuthStateChanged(user => {
      if (user) {
        app.database().ref().child('users/'+user.uid+'/firstname').on('value', snapshot => {
        
          setName(snapshot.val()) 
           
          })
          app.database().ref().child('users/'+user.uid+'/email').on('value', snapshot => {
          
            setEmail(snapshot.val()) 
            
            })
      }
     
  })
  
    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  
  async function handleSubmit (e) {
    e.preventDefault() 
    if (password) {
      if (password < 8) {
        setError(true)
        return setMessage('Password needs to be 8 characters or more');
    }
      if (!Cpassword) {
        setError(true)
        return setMessage("Passwords is required")
    } else if (password == Cpassword) {
        setError(true)
        return setMessage("Passwords should not match")
    }
    else if (!/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(password))
    {
        setError(true)
        return setMessage("Your Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character")
    }
    
    if (!email) {
        setError(true)
        return setMessage('Email required');
    } else if (!/\S+@\S+\.\S+/.test(email)) {
        setError(true)
        return setMessage('Email address is invalid');
    }
    
    if (!name) {
        setError(true)
        return setMessage('First name required');
    }
    if (!last) {
        setError(true)
      return setMessage('Last name required');
      }
    
      var user = app.auth().currentUser;
      var cred = EmailAuthProvider.credential(
          email, password);
          setLoading(true)
          await user.reauthenticateWithCredential(cred).then(() => {
      user.updatePassword(Cpassword).then(() => {
        user.updateEmail(email).then(() => {
          app.database().ref("users/"+user.uid).update({
            firstname:name,
            lastname:last,
            email:email,
          })
          setSuccess("Successful!")
        }).catch((error) => {
          setError(true)
          setMessage("try again")
        });
      }).catch((error) => { console.log(error); });
    }).catch((error) => { console.log(error); });
    setLoading(false)
  } 
  else 
  {
    if (!email) {
      setError(true)
      return setMessage('Email required');
  } else if (!/\S+@\S+\.\S+/.test(email)) {
      setError(true)
      return setMessage('Email address is invalid');
  }
  
  if (!name) {
      setError(true)
      return setMessage('First name required');
  }
  if (!last) {
      setError(true)
    return setMessage('Last name required');
    }
  
    var user = app.auth().currentUser;
    var cred = EmailAuthProvider.credential(
        email, password);
        setLoading(true)
       await user.updateEmail(email).then(() => {
          app.database().ref("users/"+user.uid).update({
            firstname:name,
            lastname:last,
            email:email,
          })
          setSuccess("Successful!")
        }).catch((error) => {
          setError(true)
          setMessage("try again")
        });
  setLoading(false)
  }
  
  
  }


  return (

    <MDBox position="relative" mb={5}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.6),
              rgba(gradients.info.state, 0.6)
            )}, url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
       <form className={classes.form} noValidate>
              
              {error ? (
                  <Alert onClose={() => {setError(false)}} severity="error">
                      {message}.
                  </Alert>
              ) : null}
              {success ? (
                  <Alert onClose={() => {setSuccess(false)}} severity="success">
                      {success}
                  </Alert>
              ) : null}
              <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="firstname"
                      label="First name"
                      name="name"
                      autoComplete="name"
                      autoFocus
                      value={name}
                      onChange={e => setName(e.target.value)}
                  />
                  <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      autoFocus
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                  />
                  <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Current Password"
                      type={show ? 'text' : 'password'}
                      id="password"
                      autoComplete="current-password"
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                  />  
                  <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="New password"
                      type={show ? 'text' : 'password'}
                      id="password"
                      autoComplete="current-password"
                      value={Cpassword}
                      onChange={e => setCPassword(e.target.value)}
                  />  
                  <MDButton
                      variant="gradient" color="info" fullWidth
                      className={classes.submit}
                      display={loading}
                      onClick={handleSubmit}
                  >
                      Update
                  </MDButton>
                
              </form>  
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
