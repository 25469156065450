import { auth } from '../../../firebase';
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

function Logout() {
    let navigate = useNavigate();
  useEffect(() => {
      auth.signOut();
      navigate("/authentication/sign-in")
  }, [])
  return (
    <div>
    </div>
  )
}

export default Logout